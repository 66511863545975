import { Button, IconContainer, Typography } from 'components/common'
import { FTextField } from 'components/formik-factory'
import { Form, Formik } from 'formik'
import { isDisabledFormik } from 'helpers'
import { Lock, Mail } from 'react-feather'
import { useTranslation } from 'react-i18next'

import styles from './LoginForm.module.scss'
import { loginValidationSchema } from './LoginForm.validation-schema'
import { oAuthApi } from '../../api'

interface LoginFormValues {
  email: string
  password: string
}

const initialValues: LoginFormValues = { email: '', password: '' }

const LoginForm = () => {
  const [performLogin, { isLoading, isError, error }] =
    oAuthApi.useLoginMutation()
  const { t } = useTranslation()

  const handleSubmit = async (values: LoginFormValues) => {
    try {
      await performLogin({
        username: values.email,
        password: values.password,
      }).unwrap()
    } catch (err) {}
  }

  const handleEnterKey = (evt: React.KeyboardEvent) => {
    evt.stopPropagation()
    const target = evt.target as HTMLInputElement
    if (evt.key === 'Enter' && target.form) target.form.requestSubmit()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty }) => (
        <Form className={styles.root}>
          {!isLoading && isError && error && 'data' in error && (
            <Typography
              fontWeight="bold"
              color="error"
              className={styles.error}
            >
              {(error.data as any).message}
            </Typography>
          )}
          <FTextField
            startAdornment={<IconContainer size="small" icon={<Mail />} />}
            type="email"
            name="email"
            id="email"
            label={t('login.email') ?? ''}
            size="medium"
            className={styles.input}
          />
          <FTextField
            startAdornment={<IconContainer size="small" icon={<Lock />} />}
            type="password"
            name="password"
            id="password"
            label={t('login.password') ?? ''}
            className={styles.input}
            onKeyDown={handleEnterKey}
          />

          <Button
            type="submit"
            disabled={isDisabledFormik(isLoading, isValid, dirty)}
          >
            {t('login.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
