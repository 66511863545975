import { Typography } from 'components/common'
import { Link } from 'react-feather'

import styles from './MetadataListProperty.module.scss'
import { ExternalReferencesValue } from '../../components/GuidelineForm/types'

interface Props {
  title: string
  values?: string | ExternalReferencesValue[] | null
}

const MetadataListProperty = ({ title, values }: Props) => {
  return (
    <div className={styles.property}>
      <Typography
        className={styles.title}
        color="label"
        size="small"
        fontWeight="medium"
      >
        {title}
      </Typography>
      {!values && (
        <Typography className={styles.content} element="span">
          -
        </Typography>
      )}
      {values && typeof values !== 'string' && values.length > 0 && (
        <ul className={styles.list}>
          {values.map((v, i) => {
            if (!v.value) return <>-</>
            return (
              <li key={`extRef-${i}`}>
                {v.value.startsWith('http') && (
                  <a
                    href={v.value}
                    title={v.name ? v.name : ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {v.name ? v.name : v.value} <Link size="14" />
                  </a>
                )}
                {!v.value.startsWith('http') && <>{v.value}</>}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default MetadataListProperty
